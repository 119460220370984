import "../../styles/Footer.css";
import Marquee from "react-fast-marquee";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function Footer() {
  function rollUp(){
    window.scrollTo(0,0);
  }

  return (
    <div className="Footer" id="Footer">
      <div className="footer_Container1">
        <div className="footer_div1">
          <div className="div1_text" style={{textAlign: "center"}}>
            <h3>
                Serving 👨‍💻 worldwide
            </h3>
          </div>
          <div className="footer_social_icons" style={{textAlign: "center"}}>
            <div className="social__icons" id="social__icons">
              <span>
                <a rel="noreferrer" target="_blank" href="https://www.instagram.com/igeeksmedia_/">
                  <InstagramIcon />
                </a>
              </span>
              <span>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/igeeksmedia">
                  <TwitterIcon />
                </a>
              </span>
              <span>
                <a
                  rel="noopener"
                  href="https://www.linkedin.com/company/igeeksmedia"
                >
                  <LinkedInIcon />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="footer_div2">
          <div className="linkedin">
            <Link to="/contact">
              <p onClick={rollUp}>CONTACT US</p>
            </Link>{" "}
            <span className="to_top"></span>
          </div>
          <div className="linkedin">
            <a href="https://www.igeeksblog.com/advertise/" target="_blank" rel="noreferrer" >
              <p onClick={rollUp}>ADVERTISE WITH US</p>
            </a>{" "}
            <span className="to_top"></span>
          </div>
          <div className="careers">
            <Link to="/career">
              <p onClick={rollUp}>CAREERS</p>
            </Link>{" "}
            <span className="to_bottom"></span>
          </div>
        </div>
        <div className="footer_div3">
          <Marquee speed={150} gradient={false}>
            <div className="home__div26" id="home__div26">
              <p>info@igeeksblog.com &nbsp;</p>
            </div>
          </Marquee>
        </div>
      </div>
      <div className="footer_Container2">
        <div className="footer__lower">
          <h3>2025 © iGeeks Media Pvt. Ltd.</h3>
          <h3>
            <a rel="noreferrer" target="_blank" href="https://derest.co.in/" style={{ textDecoration: "none", color: "#f2d729" }}>
              {" "}
              Made by Derest Marketing
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Footer;
