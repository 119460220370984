import { useEffect } from "react";
import "../styles/OurTeam.css";
import Kawaii from "../components/blob/Kawaii";

// Images

import tm1 from "../images-webp/members/suraj.webp";
import tm10 from "../images-webp/members/Ava.webp";
import tm11 from "../images-webp/members/sameer.webp";
import tm15 from "../images-webp/members/chirag.webp";
import tm17 from "../images-webp/members/shobha.webp";
import tm18 from "../images-webp/members/palak.webp";
// import tm20 from "../images/members/Jibin Joseph.jpg"
import tm25 from "../images/members/Om.jpg"
// import tm26 from "../images/members/Ayush.jpg"
import tm27 from "../images/members/Hetvi.jpg"
// import tm28 from "../images/members/Kuldeep Tarapara.jpeg"
import tm30 from "../images/members/Anshul.jpg"
// import tm31 from "../images/members/Krushang.png"
import tm32 from "../images/members/Hardik.jpg"
import tm35 from "../images/members/Abbas.jpg"
import tm36 from "../images/members/Isha.jpg"
// import tm37 from "../images/members/NIkhil.jpg"
import tm38 from "../images/members/Vikhyat.jpg"

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

// ..
AOS.init();

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 50, // offset (in px) from the original trigger point
  delay: 400, // values from 0 to 3000, with step 50ms
  duration: 1000, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

function OurTeam() {
  function buttonClick() {
    document.getElementById("team__icon1").style.display = "none";
    document.getElementById("team__icon10").style.display = "block";
    document.getElementById("team__div14").style.display = "block";
  }
  function closeButton() {
    document.getElementById("team__icon1").style.display = "block";
    document.getElementById("team__icon10").style.display = "none";
    document.getElementById("team__div14").style.display = "none";
  }

  function buttonClicks() {
    document.getElementById("team__icon11").style.display = "none";
    document.getElementById("team__icon100").style.display = "block";
    document.getElementById("team__div144").style.display = "block";
  }
  function closeButtons() {
    document.getElementById("team__icon11").style.display = "block";
    document.getElementById("team__icon100").style.display = "none";
    document.getElementById("team__div144").style.display = "none";
  }

  // function buttonClickss2() {
  //   document.getElementById("team__icon14").style.display = "none";
  //   document.getElementById("team__icon103").style.display = "block";
  //   document.getElementById("team__div147").style.display = "block";
  // }
  // function closeButtonss2() {
  //   document.getElementById("team__icon14").style.display = "block";
  //   document.getElementById("team__icon103").style.display = "none";
  //   document.getElementById("team__div147").style.display = "none";
  // }

  // function buttonClickss5() {
  //   document.getElementById("team__icon17").style.display = "none";
  //   document.getElementById("team__icon106").style.display = "block";
  //   document.getElementById("team__div150").style.display = "block";
  // }
  // function closeButtonss5() {
  //   document.getElementById("team__icon17").style.display = "block";
  //   document.getElementById("team__icon106").style.display = "none";
  //   document.getElementById("team__div150").style.display = "none";
  // }

  function buttonClickss19() {
    document.getElementById("team__icon31").style.display = "none";
    document.getElementById("team__icon120").style.display = "block";
    document.getElementById("team__div164").style.display = "block";
  }
  function closeButtonss19() {
    document.getElementById("team__icon31").style.display = "block";
    document.getElementById("team__icon120").style.display = "none";
    document.getElementById("team__div164").style.display = "none";
  }

  // function buttonClickss20() {
  //   document.getElementById("team__icon32").style.display = "none";
  //   document.getElementById("team__icon121").style.display = "block";
  //   document.getElementById("team__div165").style.display = "block";
  // }
  // function closeButtonss20() {
  //   document.getElementById("team__icon32").style.display = "block";
  //   document.getElementById("team__icon121").style.display = "none";
  //   document.getElementById("team__div165").style.display = "none";
  // }

  // function buttonClickss27() {
  //   document.getElementById("team__icon39").style.display = "none";
  //   document.getElementById("team__icon128").style.display = "block";
  //   document.getElementById("team__div172").style.display = "block";
  // }
  // function closeButtonss27() {
  //   document.getElementById("team__icon39").style.display = "block";
  //   document.getElementById("team__icon128").style.display = "none";
  //   document.getElementById("team__div172").style.display = "none";
  // }

  // function buttonClickss29() {
  //   document.getElementById("team__icon41").style.display = "none";
  //   document.getElementById("team__icon130").style.display = "block";
  //   document.getElementById("team__div174").style.display = "block";
  // }
  // function closeButtonss29() {
  //   document.getElementById("team__icon41").style.display = "block";
  //   document.getElementById("team__icon130").style.display = "none";
  //   document.getElementById("team__div174").style.display = "none";
  // }

  // function buttonClickss32() {
  //   document.getElementById("team__icon44").style.display = "none";
  //   document.getElementById("team__icon133").style.display = "block";
  //   document.getElementById("team__div177").style.display = "block";
  // }
  // function closeButtonss32() {
  //   document.getElementById("team__icon44").style.display = "block";
  //   document.getElementById("team__icon133").style.display = "none";
  //   document.getElementById("team__div177").style.display = "none";
  // }
  // function buttonClickss34() {
  //   document.getElementById("team__icon46").style.display = "none";
  //   document.getElementById("team__icon135").style.display = "block";
  //   document.getElementById("team__div179").style.display = "block";
  // }
  // function closeButtonss34() {
  //   document.getElementById("team__icon46").style.display = "block";
  //   document.getElementById("team__icon135").style.display = "none";
  //   document.getElementById("team__div179").style.display = "none";
  // }
  // function buttonClickss35() {
  //   document.getElementById("team__icon47").style.display = "none";
  //   document.getElementById("team__icon136").style.display = "block";
  //   document.getElementById("team__div180").style.display = "block";
  // }
  // function closeButtonss35() {
  //   document.getElementById("team__icon47").style.display = "block";
  //   document.getElementById("team__icon136").style.display = "none";
  //   document.getElementById("team__div180").style.display = "none";
  // }
  // function buttonClickss36() {
  //   document.getElementById("team__icon48").style.display = "none";
  //   document.getElementById("team__icon137").style.display = "block";
  //   document.getElementById("team__div181").style.display = "block";
  // }
  // function closeButtonss36() {
  //   document.getElementById("team__icon48").style.display = "block";
  //   document.getElementById("team__icon137").style.display = "none";
  //   document.getElementById("team__div181").style.display = "none";
  // }

  function buttonClickss38() {
    document.getElementById("team__icon50").style.display = "none";
    document.getElementById("team__icon139").style.display = "block";
    document.getElementById("team__div183").style.display = "block";
  }
  function closeButtonss38() {
    document.getElementById("team__icon50").style.display = "block";
    document.getElementById("team__icon139").style.display = "none";
    document.getElementById("team__div183").style.display = "none";
  }

  useEffect(() => {
    document.getElementById("team__icon1").onclick = function () {
      buttonClick();
    };
    document.getElementById("team__icon10").onclick = function () {
      closeButton();
    };

    document.getElementById("team__icon11").onclick = function () {
      buttonClicks();
    };
    document.getElementById("team__icon100").onclick = function () {
      closeButtons();
    };

    // document.getElementById("team__icon14").onclick = function () {
    //   buttonClickss2();
    // };
    // document.getElementById("team__icon103").onclick = function () {
    //   closeButtonss2();
    // };

    // document.getElementById("team__icon17").onclick = function () {
    //   buttonClickss5();
    // };
    // document.getElementById("team__icon106").onclick = function () {
    //   closeButtonss5();
    // };

    // document.getElementById("team__icon31").onclick = function () {
    //   buttonClickss19();
    // };
    // document.getElementById("team__icon120").onclick = function () {
    //   closeButtonss19();
    // };

    // document.getElementById("team__icon32").onclick = function () {
    //   buttonClickss20();
    // };
    // document.getElementById("team__icon121").onclick = function () {
    //   closeButtonss20();
    // };

    // document.getElementById("team__icon39").onclick = function () {
    //   buttonClickss27();
    // };
    // document.getElementById("team__icon128").onclick = function () {
    //   closeButtonss27();
    // };

    // document.getElementById("team__icon41").onclick = function () {
    //   buttonClickss29();
    // };
    // document.getElementById("team__icon130").onclick = function () {
    //   closeButtonss29();
    // };

    // document.getElementById("team__icon44").onclick = function () {
    //   buttonClickss32();
    // };
    // document.getElementById("team__icon133").onclick = function () {
    //   closeButtonss32();
    // };

    // document.getElementById("team__icon47").onclick = function () {
    //   buttonClickss34();
    // };
    // document.getElementById("team__icon136").onclick = function () {
    //   closeButtonss34();
    // };

    // document.getElementById("team__icon47").onclick = function () {
    //   buttonClickss35();
    // };
    // document.getElementById("team__icon136").onclick = function () {
    //   closeButtonss35();
    // };

    // document.getElementById("team__icon48").onclick = function () {
    //   buttonClickss36();
    // };
    // document.getElementById("team__icon137").onclick = function () {
    //   closeButtonss36();
    // };

    document.getElementById("team__icon50").onclick = function () {
      buttonClickss38();
    };
    document.getElementById("team__icon139").onclick = function () {
      closeButtonss38();
    };
  }, []);

  return (
    <HelmetProvider>
    <div className="our__div1">
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Meet Tech Experts and Enthusiasts | iGeeks Media Pvt. Ltd.</title>
<meta name="title" content="Meet Tech Experts and Enthusiasts | iGeeks Media Pvt. Ltd." />
<meta name="description" content="Meet the amazing team at iGeeks Media Pvt. Ltd. We are
Apple geeks, editors, writers, content creators, and SEO analysts that
assure the delivery of the best Apple content globally." />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/our-team/" />
<meta property="og:title" content="Meet Tech Experts and Enthusiasts | iGeeks Media Pvt. Ltd." />
<meta property="og:description" content="Meet the amazing team at iGeeks Media Pvt. Ltd. We are
Apple geeks, editors, writers, content creators, and SEO analysts that
assure the delivery of the best Apple content globally." />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/our-team/" />
<meta property="twitter:title" content="Meet Tech Experts and Enthusiasts | iGeeks Media Pvt. Ltd." />
<meta property="twitter:description" content="Meet the amazing team at iGeeks Media Pvt. Ltd. We are
Apple geeks, editors, writers, content creators, and SEO analysts that
assure the delivery of the best Apple content globally." />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="our__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="carrer__div8" id="our_team">
            <p>
              <span>Our </span>
              <span>Team</span>
            </p>
          </div>
        </div>
        <div className="team__div1">
          <div className="team__div2">
            <div className="team__div3">
              <p>“Everyone here has the sense that right now is one of those moments when we are influencing the future.” <span style={{color: "#f2d729"}}>- Steve Jobs</span></p>
            </div>
          </div>
          <div className="team__div4">
            <div className="team__div5">
              <div data-aos="fade-up" className="team__div6 01 Suraj">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/surajpadmasali/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm1} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Suraj</p>
                      <p>Padmasali</p>
                    </div>
                    <div className="team__div7">
                  <p>Operations Head</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon1" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon10"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div14">
                    <p>
                    Suraj is the man who knows the ABCs of digital marketing. With over 10+ years of experience as an internet marketer, Suraj is one of the best Organic SEO and social media marketers in the tech biz. At iGeeksBlog, he leads the content team and helps ideate and edit how-to tutorials, troubleshooting guides, and more. Being an avid fan of Apple products himself, he constantly explores the latest tips and tricks to share with iGeeksBlog readers. Also a fitness freak, you can find him working out at the gym or listening to retro music when not on his screen.
                    </p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" className="team__div60  18 Palak">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/palakconnects/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm18} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Palak </p>
                      <p>Shah</p>
                    </div>
                    <div className="team__div7">
                  <p>Senior HR Executive</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon11" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon100"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div144">
                    <p>
                    Palak is a savvy HR professional with an entrepreneurial notion. Her forte is hiring top talent, performance management, and workforce planning. She believes in creating "Humans" rather than just "Resources" within the organization. Beyond work, Palak enjoys reading and engaging in fascinating conversations on entrepreneurship, psychology, fashion, tech, history, space, and art. In her leisure time, she enjoys sipping on tea, doing her nails, coloring mandalas, and playing BGMI while also nurturing her daughter's curiosities and exploring her areas of interest.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div data-aos="fade-up" className="team__div60 right1 18 Isha">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/isha-jariwala-4421b51a8/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm36} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Isha</p>
                      <p>Jariwala</p>
                    </div>
                    <div className="team__div7">
                  <p>HR Executive</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon48" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon137"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div181">
                    <p>
                    Meet Isha, a dynamic HR and operations professional who brings a passion for growth and development to every aspect of her life. She excels in HR administrative tasks, operational streamlining, and workforce optimization to drive organizational success. Isha is dedicated to fostering a workplace culture that prioritizes holistic individual development, transforming them into valuable assets. Outside of work, Isha is an adventurous soul and a selenophile, finding joy in dance, cooking, and badminton. In her leisure time, Isha immerses herself in diverse hobbies, always seeking opportunities for learning and personal enrichment.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60  11 Sameer">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/sameer-sharma-b3012911b/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm11} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10 right10">
                    <div className="team__div11">
                      <p>Sameer </p>
                      <p>Sharma</p>
                    </div>
                    <div className="team__div7">
                  <p>Video Editor & Content Creator</p>
                </div>
                    <div className="team__div12 right12">
                      <div className="team__div13">
                        <AddIcon id="team__icon14" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon103"
                          className="team__icon100 right100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14 right14" id="team__div147">
                    <p>
                    Tech, Bikes, and Games are three things that define Sameer's life. Being a former Journalism and Mass Communication graduate, he has a fine understanding of working in the Tech Media World. Sameer has been fascinated by technology for the past 13 years and is always on the hunt for new technology releases and events. He presently edits and creates videos for the iGeeksBlog Hindi YouTube channel. When he is not working on videos, he enjoys riding his bike through the wilderness. Have you checked his podcast yet?
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60 right1 15 Chirag">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/nayakchirag5/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm15} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Chirag </p>
                      <p>Nayak</p>
                    </div>
                    <div className="team__div7">
                  <p>Video Editor</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon17" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon106"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div150">
                    <p>
                    Chirag is an iGeeksBlog video editor that concentrates on beautifying and refining iGeeksBlog's YouTube and Instagram video content. He loves bringing imagery to life and is always trying out new approaches and styles. He's worked on prime-time TV shows, reality shows, documentaries, movie trailers, and music video clips, among other things. He can be seen playing video games, watching movies, or listening to music when he is not at the editing table.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60  t19 Hardik_Kargathara">
                
                <div className="team__div8">
                  <a href=" https://www.linkedin.com/in/hopeofhardik/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm32} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Hardik</p>
                      <p>Kargathara</p>
                    </div>
                    <div className="team__div7">
                  <p>Social Media Strategist</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon46" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon135"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div179">
                    <p>
                    Hardik is our Social Media Strategist. With a passion for digital marketing and a keen understanding of social media platforms, Hardik excels in creating effective strategies to enhance brand visibility, engage audiences, and drive organic growth. Armed with a deep knowledge of the latest industry trends and insights, he leverages innovative tactics to optimize social media campaigns and maximize results.
                    </p>
                  </div>
                </div>
              </div> */}
              <div data-aos="fade-up" className="team__div60 right1 10 Ava">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/ava-biswas/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm10} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Ava </p>
                      <p>Biswas</p>
                    </div>
                    <div className="team__div7">
                  <p>Content Writer</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon31" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon120"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div164">
                    <p>
                    A geeky potato who loves to decode the tech through words. Her honest product reviews help our readers to take a breath of relief and check out the most suitable products for themselves. Besides, she writes easy-to-do tutorials with extensive research so that everyone may return with a solution always. You may find her cooking, exploring different cuisines, and vlogging if she is not typing.
                    </p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" className="team__div60  10 Vikhyat">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/vikhyat-rishi-066692109" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm38} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Vikhyat</p>
                      <p>Rishi</p>
                    </div>
                    <div className="team__div7">
                  <p>Content Writer</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon50" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon139"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div183">
                    <p>
                    Vikhyat has a bachelor's degree in Electronic and Communication Engineering and over five years of writing experience. His passion for technology and Apple products led him to iGeeks, where he specializes in writing App features, How To's and app troubleshooting guides for fellow Apple users. When not typing away on his MacBook, he loves exploring the real world.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div data-aos="fade-up" className="team__div60 right1 17 Shobha">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/shobha-tiwari-3a6aa8b1/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm17} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Shobha </p>
                      <p>Tiwari</p>
                    </div>
                    <div className="team__div7">
                  <p>Social Media Executive</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon32" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon121"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div165">
                    <p>
                    Journalist, Researcher, Script Writer, Digital Geek Inquisitorial, Shutterbug, AND All Around Seeker.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60  t19 Anshul">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/anshulsharma1731" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm30} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Anshul </p>
                      <p>Sharma</p>
                    </div>
                    <div className="team__div7">
                  <p>Content Creator</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon44" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon133"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div177">
                    <p>
                    Say hello to Anshul Sharma, a passionate Content Creator at iGeeks Media. He
enjoys sharing the latest Apple, tech and AI tools insights. Besides that, Anshul is
an avid traveler, cyclist, and bookworm, who loves exploring the world one
adventure at a time.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60 right1 t19 Hetvi">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/hetvi-ranpura" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm27} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Hetvi </p>
                      <p>Ranpura</p>
                    </div>
                    <div className="team__div7">
                  <p>SEO Executive</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon41" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon130"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div174">
                    <p>
                    Hetvi Ranpura is a proficient SEO executive. She has extensive knowledge of
various aspects of SEO, ranging from on-page optimization to off-page strategies
and technical SEO. Besides her professional expertise, she is an ardent traveler,
hiker, dedicated binge-watcher, and enthusiastic foodie. Her diverse interests fuel
her creativity and inspire her to deliver outstanding results in the ever-evolving
world of SEO.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60  t19 Om">
                
                <div className="team__div8">
                  <a href="https://in.linkedin.com/in/omdbagul" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm25} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Om </p>
                      <p>Bagul</p>
                    </div>
                    <div className="team__div7">
                  <p>Cinematographer</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon39" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon128"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div172">
                    <p>
                    Om is a talented Cinematographer/Filmmaker with boundless energy and
exceptional skills in identifying the perfect lighting and aesthetics for videos. He
is a self-cultivated creative individual who has been involved in a variety of
projects ranging from short films to product commercials to song covers. At the
age of 17, he was already winning International Film Contests and providing
wings to his filmmaking career. Creating something new every day is what
drives him, and being a part of the IGM team allows him to do that.
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div data-aos="fade-up" className="team__div60 right1 t19 Abbas">
                
                <div className="team__div8">
                  <a href="https://www.linkedin.com/in/abbas-bhatiya-99268515a/" target="_blank" rel="noopener noreferrer">
                    <div className="team__div9">
                      <img src={tm35} className="team__img1" alt="" />
                    </div>
                  </a>
                  <div className="team__div10">
                    <div className="team__div11">
                      <p>Abbas</p>
                      <p>Bhatia</p>
                    </div>
                    <div className="team__div7">
                  <p>Graphic Designer</p>
                </div>
                    <div className="team__div12">
                      <div className="team__div13">
                        <AddIcon id="team__icon47" className="team__icon1" />
                        <RemoveIcon
                          id="team__icon136"
                          className="team__icon100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="team__div14" id="team__div180">
                    <p>
                    Mountains, chai, and books are the perfect combination for Abbas's life. Being a former graphic designer for over 6+ years, he has a keen understanding of design details that you might not even notice, but they hold importance. Fascinated by science, space, and technology, he's always updated with current tech. He presently creates all the graphic design work for our platforms. When he's not working on graphics, he enjoys reading books or searching for new design tutorials. Oh, and a quick update – he's the Lightning McQueen of design IYKYK.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
  );
}

export default OurTeam;
